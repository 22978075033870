.form {
    display: block;
    overflow-y: auto;
    text-align: left;
    margin: 16px 0 0;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .fieldWrapper {
        display: flex;

        @media screen {
            flex-direction: column;
        }
    }

    .field {
        margin-bottom: 12px;

        & > div {
            color: var(--primary-text-color);

            fieldset {
                border-color: var(--primary-text-color) !important;
                color: var(--primary-text-color);
            }

            &:hover {
                fieldset {
                    border-color: var(--primary-text-color) !important;

                    &::placeholder {
                        color: var(--primary-alt-border-color);
                    }
                }
            }

            input {
                color: var(--primary-text-color);
            }

            & > div {
                color: var(--primary-text-color) !important;
            }
        }
        
        & > p {
            color: var(--secondary-text-color);
            font-size: 14px;
        }
    }
}
