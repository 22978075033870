.light-mode {
    --primary-alt-text-color: #fff;
    --primary-text-color: #000;
    --primary-alt-border-color: rgba(255, 255, 255, 0.75);
    --primary-border-color: rgba(0, 0, 0, 0.75);
    --secondary-text-color: #243f90;

    --primary-transparent-50: rgba(0, 0, 0, 0.25);
    --primary-transparent-35: rgba(0, 0, 0, 0.35);

    --contrast-background-color: rgba(0, 0, 0, 0.05);
}
