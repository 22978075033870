.dark-mode {
    --primary-alt-text-color: #000;
    --primary-text-color: #FFF;
    --primary-alt-border-color: rgba(0, 0, 0, 0.75);
    --primary-border-color: rgba(255, 255, 255, 0.75);

    --secondary-text-color: #FFF34A;

    --primary-transparent-50: rgba(255, 255, 255, 0.5);
    --primary-transparent-35: rgba(255, 255, 255, 0.35);

    --contrast-background-color: rgba(255, 255, 255, 0.21);
    --contrast-background: rgba(255, 255, 255, 255);
}
