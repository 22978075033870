.headerWrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 12px;

    &.showItems {
        border-bottom: 1px solid var(--primary-border-color)
    }

    .header {
        display: flex;
        justify-content: center;

        .icons {
            display: flex;
            align-items: center;
        }

        .shop {
            path {
                fill: var(--primary-text-color)
            }
        }

        .toggle {
            margin: 0 12px;

            path {
                fill: var(--primary-text-color)
            }

            &.open {
                transform: rotate(180deg);
            }
        }

        .total {
            width: 100%;
            font-weight: 500;
            font-size: 24px;
            line-height: 150%;
            text-transform: uppercase;
            margin: auto;
            color: var(--secondary-text-color);
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    background: var(--contrast-background-color);

    .cartItem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 16px;

        .icon {
            width: 60px;
            height: 60px;
        }

        .text {
            font-size: 16px;
            color: var(--primary-text-color);
            padding: 8px 0 0 16px;
        }

        .countWrapper {
            display: flex;
            align-items: center;

            .price {
                margin: 0 0 0 16px;
            }
        }
    }

    .divider {
        width: 80%;
        padding: 16px 0 0;
        margin: 0 0 16px;
        border-bottom: 1px solid  var(--primary-border-color);
    }

    .collectibles,
    .attached {
        font-weight: 700;
        color: var(--secondary-text-color);
    }

    .collectibles {
        font-size: 32px;
    }

    .attached {
        font-size: 16px;
    }

    .product {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        margin: 8px 0 0;

        .name {
            width: 100%;
            padding: 16px 0 0;
            font-size: 16px;
            color: var(--primary-text-color);
        }

        .imageWrapper {
            display: flex;
            padding: 8px 0 0;

            .image {
                max-width: 100%;
                max-height: 120px;
                border-radius: 8px;
                object-fit: contain;
            }
        }

        .size {
            width: 100%;
            padding: 0 0 8px;
            font-size: 16px;
            color: var(--primary-text-color);
        }
    }
}