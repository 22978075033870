@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap");
@import "./styles/styles.scss";

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}
.uppy-Dashboard-poweredBy {
    display: none !important;
}

@media (max-width: 750px) {
    .uppy-Dashboard-inner {
        top: 125px !important;
        bottom: 155px !important;
    }
}

.no-paddings {
    padding: 0px !important;
}
.no-marings {
    margin: 0px !important;
}

.button-cancel-notistack {
    background: white;
    border: none;
    border-radius: 8px;
    width: 80px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    color: black;
    outline: none;
}
.button-ok-notistack {
    background: white;
    border: none;
    border-radius: 8px;
    width: 80px;
    height: 30px;
    cursor: pointer;
    color: black;
    outline: none;
}
.login-select {
    appearance: none;
}

.login-input {
    max-width: 260px;
    box-shadow: none !important;
    border: 1px solid #000000;
    outline: none;
    position: relative;
    height: 46px;
    margin-bottom: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 14px;
    border-radius: 5px;
    font-size: 16px;

    @media screen and (max-width: 1024px) {
        height: 42px;
    }
}

.login-input:focus {
    box-shadow: none !important;
    border-color: black;
    outline: none;
}
.login-input:active {
    outline: none;
    box-shadow: none !important;
}
.login-input:hover {
    outline: none;
    box-shadow: none !important;
}
.input-container {
    width: 100%;
    position: relative;
}

.toggle-password-visibility {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-70%);
}

.login-input {
    padding-right: 40px;
}

.login-w-input {
    text-align: center;
    display: block;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
    background-color: #ffffff;
}
.login-input-bday-div .birthday-label {
    background-color: #EFFF00;
    color: #000;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
}
select:invalid { color: #999; }
.login-date-input {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    text-align: center;
}
.login-w-input::placeholder {
    color: #999;
}

.password {
    padding-right: 35px;
}

.login-w-input:focus {
    outline: 0;
}
.login-w-input[disabled],
.login-w-input[readonly],
fieldset[disabled] .login-w-input {
    cursor: not-allowed;
    background-color: #eeeeee;
}
.cursor-pointer {
    cursor: pointer;
}

.login-link {
    text-decoration: none;
    max-width: 100%;
    display: inline-block;
    color: #233e90;
    cursor: pointer;
}
.login-page-link {
    color: #000;
    font-weight: 600;
    text-decoration: none;
}

.login-page-link.terms {
    color: #383333;
}
.login-head {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.3em;
    font-weight: 300;
    font-family: Nunito, sans-serif;
}
.secondary-action {
    font-size: 15px;
    line-height: 1.6em;
}
.login-button-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 4px 18px;
    border-radius: 6px;
    background-color: #e15633;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
}
.login-button-2:hover {
    background-color: #233e90;
}
.login-button-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 4px 18px;
    border-radius: 6px;
    background-color: #233e90;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
}
.login-button-3:hover {
    background-color: #e15633;
}
.position-relative {
    position: relative;
}
.opacity-5 {
    opacity: 0.5;
}
.bold-text {
    font-weight: 700;
}
.w-button {
    display: inline-block;
    color: white;
    border: 0;
    text-decoration: none;
    cursor: pointer;
}
.text-span-3 {
    color: #233e90;
}
.link-14 {
    color: #383333;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}
.terms-text {
    max-width: 247px;
    margin-top: 12px;
    color: rgba(51, 51, 51, 0.6);
    font-size: 13px;
    text-decoration: none;
    font-family: Nunito, sans-serif;
}
.nav-tabs {
    border: none !important;
}
.red-text {
    color: #ff5636 !important;
}
.playing-video {
    transform: rotate(90deg);
}
.news-video-play-button {
    position: absolute;
    bottom: 15px;
    right: 20px;
}
.news-video-play-button-desktop {
    right: 15% !important;
}
.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.desktop-active-pointer {
    position: absolute;
    top: 5px;
    left: -30px;
    height: 20px;
    width: 20px;
}
.active-bullet {
    filter: invert(26%) sepia(96%) saturate(7073%) hue-rotate(357deg) brightness(100%) contrast(97%);
}
.desktop-side-menu-text-width {
    width: 100px;
}
.sidebar-icon {
    width: 30px;
    height: 30px;
}
.sidebar-icon-small {
    width: 10px;
    height: 30px;
}
.text-red {
    color: #ff0000 !important;
}

.pushuser-agree-button,
.pushuser-agree-button:focus,
.pushuser-agree-button:active,
.pushuser-agree-button:hover {
    outline: none !important;
    border: none !important;
    background-color: #ff5636 !important;
    font-size: 14px !important;
    box-shadow: none !important;
}
.desktop-fixed-width {
    width: 464px;
}
@media screen and (max-width: 767px) {
    .login-head {
        font-size: 20px;
    }
    .login-button-2 {
        margin-top: 15px;
    }
}

.StripeElement {
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 14px;
    margin-bottom: 8px;
}

input[type="checkbox"] {
    height: 30px;
    width: 30px;
    border-radius: 10px;
}
input[type="checkbox"]:checked {
    background-color: #e15633;
}
input[type="radio"] {
    height: 24px;
    width: 24px;
    border-radius: 10px;
}
input[type="radio"]:checked {
    background-color: #e15633;
}
.form-check-label {
    margin-left: 20px;
    font-size: 24px;
}
.pac-container {
    z-index: 11114 !important;
}

.pac-item-query,
.pac-matched,
.pac-item-query:hover,
.pac-matched:hover {
    color: var(--primary-text-color);
    font-weight: 700;
}


.modal {
    z-index: 1111112;

    &.terms-modal-wrapper {
        z-index: 1111112;
    }
}

@media screen and (max-width: 767px) {
    .modal-dialog {
        //TODO перевіріти чи не ломає це інші модалки
        //max-width: 100% !important;
    }
}
@media screen and (min-width: 767px) {
    .modal-dialog {
        /* max-width: 340px !important; */
    }
}
.ic-modal {
    padding: 12px;
}
.ic-modal .modal-content {
    border: 1px solid #223b95 !important;
    border-radius: 20px !important;
}
.ic-mobile-token .modal-dialog,
.ic-mobile-cart .modal-dialog,
.ic-mobile-chat .modal-dialog {
    height: 100%;
    margin: 0px !important;
}
.ic-mobile-token .modal-content,
.ic-mobile-cart .modal-content,
.ic-mobile-chat .modal-content {
    height: 100%;
    // background: #faf0f0;
}
.ic-mobile-chat .modal-content {
    overflow-y: scroll;
}
.society-modal-spacing {
    padding: 40px 0;
}

.portal-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.alice-carousel {
    height: 100%;
    width: 100%;
}
.alice-carousel div {
    height: 100%;
    width: 100%;
    display: flex;
}
.alice-carousel__dots {
    bottom: 30px;
    position: absolute;
    left: 0;
    right: 0;
}
.alice-carousel__dots-item {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

body > iframe {
    pointer-events: none;
}

.web3modal-modal-lightbox {
    z-index: 1111 !important;
}

.custom-snackbar-root {
    z-index: 1111113 !important;
}

.MuiDialog-root {
    z-index: 1111113 !important;
}

.terms-modal-wrapper {
    background: rgba(0, 0, 0, 0.4);
}

.terms-modal-wrapper > .modal-dialog {
    @media screen and (min-width: 767px) {
        min-width: 600px;
    }
}

.terms-modal-wrapper .modal-content {
    background-color: rgba(255, 255, 255, 1);
}

.terms-modal-wrapper .terms-modal {
    padding: 24px;
}

.react-switch-bg {
    border: 1px solid var(--primary-text-color);
}

.react-switch-handle {
    box-shadow: 0px 0px 0px 1px var(--primary-text-color);
}

.react-switch-bg--black {
    .react-switch-bg {
        background: #000;
        border: 1px solid #000;
    }

    .react-switch-handle {
        box-shadow: 0px 0px 0px 1px #000;
    }
}

.dit {
    width: 100%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-bottom: 1rem;
}
.dit-logout {
    cursor: pointer;
    padding: 1rem;
}

.w-60 {
    width: 60%;
}

.d-grid {
    display: grid;
}

.gameModalMobile .modal-content{
    border-radius: 35px;
    border: 2px solid blue;
    width: 65%
}

.gameModalMobile .modal-dialog{
    justify-content: center;
    width: 60%
}

.gameModal .modal-content{
    border-radius: 35px;
    border: 2px solid blue;
    justify-content: center;
    padding: 0 10px;
    width: 90%;
    font-size: 12px;
}
.modal.show .modal-dialog {
    justify-content: center!important;
    width: auto;
}


.freeModal .modal-content{
    border-radius: 35px;
    border: 2px solid blue;
    width: 60%
}

.freeModal .modal-dialog{
    justify-content: center;
    width: 70%
}

.freeModal .modal-content{
    border-radius: 35px;
    border: 2px solid blue;
    justify-content: center;
    width: 75%;
    padding: 0 30px;
    font-size: 12px;
}
.modal.show .modal-dialog {
    justify-content: center!important;
    width: auto;
}
