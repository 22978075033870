.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 0;

    .cardImage {
        height: 46px;
    }

    .cardWrapper {
        margin: 16px 0 0;

        .card {
            border: 1px solid var(--primary-text-color);
        }
    }

    .field {
        margin: 8px 0;

        & > div {
            color: var(--primary-text-color);

            fieldset {
                border-color: var(--primary-text-color) !important;
                color: var(--primary-text-color);
            }

            &:hover {
                fieldset {
                    border-color: var(--primary-text-color) !important;

                    &::placeholder {
                        color: var(--primary-alt-border-color);
                    }
                }
            }

            input {
                color: var(--primary-text-color);
            }

            & > div {
                color: var(--primary-text-color) !important;
            }
        }

        & > p {
            color: var(--secondary-text-color);
            font-size: 14px;
        }
    }

    .address {
        display: flex;
        margin: 8px 0 0;
        color: var(--primary-text-color);
    }

    .section {
        display: flex;
        margin: 12px 0 0;

        .toggle {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid var(--primary-text-color);   
            cursor: pointer;

            &.active {
                background: var(--secondary-text-color);
                border: none;
            }
        }

        .titleWrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 1 1;

            .value {
                width: 100%;
                margin: auto 0;
                padding: 0 0 0 12px;
                font-size: 16px;
                color: var(--primary-text-color);
            }
        }
    }

    .enterWrapper {
        display: flex;
        width: 100%;
        margin: 16px 0 0;

        .enter {
            width: 156px;
            padding: 8px;
            margin: 20px 0 16px;
            outline: none;
            border: none;
            border-radius: 8px;
            background: var(--primary-text-color);
            color: var(--primary-alt-text-color);

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}
