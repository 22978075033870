.step {
    position: relative;
    width: 100%;
    padding: 0 16px;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0;

        .headerWrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 12px 0;
            border-bottom: 1px solid var(--primary-border-color);
            text-align: left;

            .info {
                width: 100%;
                color: var(--primary-text-color);
            }

            .edit {
                display: flex;
                width: 25%;
                padding: 12px 0 0;
                font-size: 14px;
                color: var(--secondary-text-color);
                text-align: left;
                cursor: pointer;

                &:hover {
                    opacity: 0.85;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            .section {
                display: flex;
                width: 100%;
                margin: 16px 0 0;

                .toggle {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    background: transparent;
                    border: 1px solid var(--primary-text-color);                    
                    cursor: pointer;

                    &.active {
                        background: var(--secondary-text-color);
                        border: none;
                    }
                }

                .list {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0 0 0 16px;
                    margin: auto 0;
                    flex: 1 1;

                    .subtitle,
                    .text {
                        width: 100%;
                        flex: 1 1;
                        font-size: 16px;
                        color: var(--primary-text-color);
                    }

                    .subtitle {
                        margin: 0 0 8px;
                        font-weight: 700;
                    }

                    .text {
                        margin: 0 0 0 16px;

                        &:not(:last-of-type) {
                            margin: 0 0 4px 16px;
                        }
                    }
                }
            }

            .enter {
                width: 156px;
                padding: 8px;
                margin: 20px 0 16px;
                outline: none;
                border: none;
                border-radius: 8px;
                background: var(--primary-text-color);
                color: var(--primary-alt-text-color);

                &:disabled {
                  opacity: 0.5;
                }
            }
        }
    }
}