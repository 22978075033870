.button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.025em;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin: 0px;
  padding: 8px 16px;
  font-weight: bold;

  &:disabled {
    cursor: default;
  }

  &.red {
    background: #ff0022;
    color: #ffffff;
  }

  &.yellow {
    background: Yellow;
    color: #000000;
  }
}
