body {
    &.favorite-color {
        &--green {
            --primary-favorite-color: hsl(120, 100%, 25%);
            --primary-favorite-hsl-10: hsl(120, 100%, 20%);
            --primary-favorite-hsl-15: hsl(120, 100%, 15%);
        }

        &--yellow {
            --primary-favorite-color: hsl(60, 100%, 50%);
            --primary-favorite-hsl-10: hsl(60, 100%, 60%);
            --primary-favorite-hsl-15: hsl(60, 100%, 65%);
        }

        &--red {
            --primary-favorite-color: hsl(0, 100%, 50%);
            --primary-favorite-hsl-10: hsl(0, 100%, 45%);
            --primary-favorite-hsl-15: hsl(0, 100%, 40%);
        }

        &--orange {
            --primary-favorite-color: hsl(39, 100%, 50%);
            --primary-favorite-hsl-10: hsl(39, 100%, 60%);
            --primary-favorite-hsl-15: hsl(39, 100%, 65%);
        }

        &--brown {
            --primary-favorite-color: hsl(0, 59%, 41%);
            --primary-favorite-hsl-10: hsl(0, 59%, 36%);
            --primary-favorite-hsl-15: hsl(0, 59%, 31%);
        }

        &--blue {
            --primary-favorite-color: hsl(240, 100%, 50%);
            --primary-favorite-hsl-10: hsl(240, 100%, 45%);
            --primary-favorite-hsl-15: hsl(240, 100%, 40%);
        }

        &--black {
            --primary-favorite-color: hsl(0, 0%, 0%);
            --primary-favorite-hsl-10: hsl(0, 0%, 10%);
            --primary-favorite-hsl-15: hsl(0, 0%, 15%);
        }

        &--purple {
            --primary-favorite-color: hsl(300, 100%, 25%);
            --primary-favorite-hsl-10: hsl(300, 100%, 20%);
            --primary-favorite-hsl-15: hsl(300, 100%, 15%);
        }

        &--white {
            --primary-favorite-color: hsl(0, 0%, 100%);
            --primary-favorite-hsl-10: hsl(0 0% 90% / 1);
            --primary-favorite-hsl-15: hsl(0 0% 80% / 1);
        }
    }
}
