.page {
    display: flex;
    flex-direction: column;
    align-items: center;    
    width: 100%;
    padding: 120px 20px 0;
    animation: appearAnimation 1s ease-in-out normal forwards;

    .image {
        max-width: 768px;
        margin-bottom: 36px;
        filter: drop-shadow(0px 0px 4px #00000052);
    }
    
    .view {
        display: flex;
        align-items: center;
        column-gap: 20px;

        .text {
            font-size: 18px;
            font-weight: 700;
            color: var(--primary-text-color);
        }

        .redirect {
            background: var(--primary-text-color);
            color: var(--primary-favorite-color);
        }

        @media screen and (max-width: 750px) {
            flex-direction: column;
            row-gap: 20px;
        }
    }
}

@keyframes appearAnimation {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0.25;
	}

    50% {
		opacity: 0.5;
	}

    75% {
		opacity: 0.75;
	}

	100% {
		opacity: 1;
	}
}