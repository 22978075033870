.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 0 16px;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0;

        .edit {
            display: flex;
            justify-content: flex-end;
            padding: 12px 0 0;
            font-size: 14px;
            color: var(--secondary-text-color);
            text-align: left;
            cursor: pointer;

            &:hover {
                opacity: 0.85;
            }
        }

        .deliveryInfo {
            display: flex;
            flex-direction: column;
            padding: 12px 0;
            border-bottom: 1px solid var(--primary-border-color);
            text-align: left;

            .info {
                display: flex;
                flex-direction: column;
                width: 100%;
                color: var(--primary-text-color);
            }
        }

        .deliveryOption {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid var(--primary-border-color);
            text-align: left;
            margin: 12px 0 0;
            padding: 0 0 12px;

            .value {
                width: 100%;
                margin: auto 0;
                font-size: 20px;
                color: var(--primary-text-color);
            }
        }

        .cardWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            .card {
                display: flex;
                margin: 16px 0 0;

                .toggle {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    background: transparent;
                    border: 1px solid var(--primary-text-color);                    
                    cursor: pointer;
    
                    &.active {
                        background: var(--secondary-text-color);
                        border: none
                    }
                }
    
                .image {
                    height: 46px;
                    margin: auto 0 auto 12px;
                }
            }

            .enter {
                width: 156px;
                padding: 8px;
                margin: 20px 0 16px;
                outline: none;
                border: none;
                border-radius: 8px;
                background: var(--primary-text-color);
                color: var(--primary-alt-text-color);

                &:disabled {
                opacity: 0.5;
                }
            }
        }
    }
}