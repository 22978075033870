.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 0;

    .paymentWrapper {
        margin: 16px 0 0;

        @media screen and (max-width: 768px) {
            margin: 16px 0 0 12px;
        }
    }

    .cardImage {
        height: 46px;
    }

    .cardWrapper {
        position: relative;
        margin: 20px 0 0;

        .loaderWrapper {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            opacity: 1 !important;
            z-index: 1000;
        }
    }
}