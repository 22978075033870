
.checkout-step-text {
  font-weight: 900;
  font-size: 45px;
  line-height: 53px;
}
.checkout-step {
  font-size: 40px;
  font-weight: 600;
}
.bisney-logo-checkout {
  height: 52px;
  position: absolute;
  z-index: 111113;
}
.bisney-enter-btn {
  background: #ffffff;
  border-radius: 7px;
  color: inherit;
  padding: 8px;
  border: none;
  width: 156px;
  color: #ff454f;
  margin: auto;
  &:disabled {
    opacity: 0.5;
  }
}
.bisney-guest-btn {
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 7px;
  background: transparent;
  padding: 8px;
  width: 156px;
  margin: auto;
}
.account-benefit-text {
  font-size: 14px;
  color: #ffffff;
  margin: auto;
  width: 156px;
}
.account-benefit-title {
  font-size: 15px !important;
  font-weight: 700;
}
.border-right-wrapper-checkout:before {
  content: "";
  position: absolute;
  right: 0;
  top: 5%;
  height: 90%;
  border-right: 1px solid var(--primary-border-color)
}

.text-field {
  &.search-field {
    & > div {
      fieldset {
        border-color: rgba(0, 0, 0, 0.7) !important;
        border-radius: 8px !important;
      }

      &:hover {
        fieldset {
          border-color: rgba(0, 0, 0, 1) !important;
        }
      }

      input {
        color: #000000;
      }
    }

    &.inverted {
      fieldset {
        border-color: rgba(255, 255, 255, 0.7) !important;
      }

      &:hover {
        fieldset {
          border-color: rgba(255, 255, 255, 1) !important;
        }
      }

      input {
        color: #ffffff;
      }
    }
  }

  & > div {
    fieldset {
      border-color: rgba(255, 255, 255, 0.7) !important;
    }

    &:hover {
      fieldset {
        border-color: rgba(255, 255, 255, 1) !important;
      }
    }
  }

  & > p {
    color: var(--secondary-text-color);
    font-size: 14px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  height: 100vh;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-family: "Roboto", sans-serif;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}
.StripeElement {
  width: 100%;
  height: 52px;
  background: transparent !important;
}
.carousel .control-dots {
  text-align: left !important;
}
.carousel .control-dots .dot {
  box-shadow: none !important;
  width: 16px !important;
  height: 16px !important;
}
.carousel .control-dots .dot:active,
.carousel .control-dots .dot:focus,
.carousel .control-dots .dot:hover {
  outline: none;
}
.carousel .slide .legend,
.carousel .carousel-status,
.carousel .thumbs-wrapper {
  display: none !important;
}
.control-arrow {
  display: none !important;
}
.carousel .slide {
  background: unset !important;
}
#root {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.height-200 {
  height: 200px;
}
.height-50 {
  height: 50px;
}
.yellow {
  color: #faff00;
}
.white {
  color: white;
}
.red {
  color: #ff454f;
}
.black {
  color: black;
}
.no-outline,
.no-outline:hover,
.no-outline:focus,
.no-outline:active {
  outline: none;
}
.white-background {
  background: white;
}
.yellow-background {
  background: #faff00;
}
.text-transform-uppercase {
  text-transform: uppercase;
}
.object-fit {
  object-fit: contain;
}
.margin-auto {
  margin: auto;
}
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.bisney-logo {
  width: 50px;
  filter: invert(100%) sepia(0%) saturate(9%) hue-rotate(149deg) brightness(103%) contrast(102%);
}
.border-bottom-wrapper {
  border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}
.border-right-wrapper {
  border-right: 1px solid rgba(250, 250, 250, 0.5);
}
.border-top-wrapper {
  border-top: 1px solid rgba(250, 250, 250, 0.5);
}
.border-left-wrapper {
  border-left: 1px solid rgba(250, 250, 250, 0.5);
}
.opacity-3 {
  opacity: 0.3;
}
.fixed-width {
  width: 200px;
}
.MuiInputBase-root {
  color: white !important;
  z-index: 111113;
}
.loader {
  background-color: #111;
  overflow: hidden;
  width: 157px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  z-index: 100000;
}

.loader-element {
  height: 3px;
  width: 100%;
  background: #a5d6a7;
}

.loader-element::before {
  content: "";
  display: block;
  background-color: #4caf50;
  height: 3px;
  width: 0;
  animation: getWidth 3s ease-in infinite;
}
@keyframes getWidth {
  100% {
    width: 100%;
  }
}
.white-wrapper {
  background: white;
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ff454f;
}
.bisney-end-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
}
.bisney-end-wrapper-mobile {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
.font-bold {
  font-weight: bold;
}
.side-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.font-12 {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.025em;
}
.font-10 {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.025em;
}
.bouncer-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 1 !important;
  z-index: 1000;
}
.absolute-loader {
  position: absolute !important;
}
.pac-container {
  background: var(--primary-favorite-color);
}
.pac-item,
.pac-matched {
  color: var(--primary-text-color);
  font-family: "Roboto", sans-serif;
}
.pac-item:hover,
.pac-container,
.pac-matched:hover {
  background: var(--primary-favorite-color);
}
.pac-matched:hover {
  color: var(--primary-alt-text-color);
  cursor: pointer;
}
.flex-1 {
  flex: 1;
}
.continue-link {
  color: white;
  text-decoration: none;
}
#menu-state {
  z-index: 11113 !important;
}
