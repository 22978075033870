.step {
    width: 90%;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .edit {
            display: flex;
            justify-content: flex-end;
            width: 25%;
            padding: 12px 0 0;
            font-size: 14px;
            color: var(--secondary-text-color);
            text-align: right;
            cursor: pointer;

            &:hover {
                opacity: 0.85;
            }
        }

        .deliveryInfo {
            display: flex;
            padding: 12px 0;
            border-bottom: 1px solid var(--primary-border-color);

            .info {
                display: flex;
                flex-direction: column;
                width: 100%;
                color: var(--primary-text-color);
            }
        }

        .deliveryOption,
        .paymentDetails {
            display: flex;
            margin: 12px 0 0;
            padding: 0 0 12px;
        }

        .deliveryOption {

            .value {
                width: 100%;
                margin: auto 0;
                font-size: 16px;
                color: var(--primary-text-color);
            }
        }

        .paymentDetails {
            .cardImage {
                height: 46px;
                margin: auto 0;
            }

            .cardNumber {
                width: 100%;
                margin: auto 0 auto 12px;
                font-size: 16px;
                color: var(--primary-text-color);
            }
        }

        .buyButton {
            padding: 8px 20px;
            margin: 20px auto 12px auto;
            border: none;
            outline: none;
            border-radius: 8px;
            background: var(--secondary-text-color);
            color: var(--primary-alt-text-color);
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}