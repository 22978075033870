.powered {
    width: 100%;
    padding: 0 0 20px;

    @media screen and (max-width: 1024px) {
        padding: 12px 15px 12px;
    }

    .image {
        display: block;
        width: 100px;
        height: auto;

        path,
        rect,
        polygon {
            fill: var(--primary-text-color);
        }
    }
}

.thanks {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11112;
    width: 100%;
    min-height: 100%;
    background: var(--primary-favorite-color);
}

.page {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    z-index: 11112;
    background: var(--primary-favorite-color);

    @media screen and (max-width: 1024px) {
        min-height: 100%;
        padding: 0;

        .header {
            display: flex;
            width: 100%;
            text-align: center;
            justify-content: center;
    
            .logo {
                position: absolute;
                top: 20px;
                left: 12px;
                z-index: 111113;
                height: 52px;
    
                @media screen and (max-width: 1024px) {
                    height: 30px;
                }
            }
        }
    
        .content {
            width: 100%;
            padding: 0;
    
            .basketWrapper {
                display: flex;
                flex-direction: column;
                padding: 12px 0 0;
            }
    
            .stepWrapper {
                display: flex;
                justify-content: center;
                width: 100%;
                margin: 12px 0 0;
            }
        }
    }
}
