.title {
    font-weight: 700;
    color: var(--primary-text-color);
}

.form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    text-align: left;
    width: 95%;
    padding: 0 0 30px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .stripeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 220px;
        height: 49px;
        outline: none;
        border-radius: 5px;
        border: 0.25px solid var(--primary-text-color);
        background: #fff;

        .logo {
            height: 40px;
            width: auto;
        }
    }

    .fieldWrapper {
        display: flex;

        @media screen {
            flex-direction: column;
        }
    }

    .field {
        margin-bottom: 12px;

        & > div {
            color: var(--primary-text-color);

            fieldset {
                border-color: var(--primary-text-color) !important;
                color: var(--primary-text-color);
            }

            &:hover {
                fieldset {
                    border-color: var(--primary-text-color) !important;

                    &::placeholder {
                        color: var(--primary-alt-border-color);
                    }
                }
            }

            input {
                color: var(--primary-text-color);
            }

            & > div {
                color: var(--primary-text-color) !important;
            }
        }
        
        & > p {
            color: var(--secondary-text-color);
            font-size: 14px;
        }
    }

    .enter {
        width: 156px;
        padding: 8px;
        margin: auto;
        border: none;
        border-radius: 8px;
        background: var(--primary-text-color);
        color: var(--primary-alt-text-color);

        &:disabled {
          opacity: 0.5;
        }
    }
}
