.header {
    width: 60%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--primary-text-color);
    margin: 0 auto 16px auto;

    .total {
        width: 100%;
        padding: 0 0 16px;
        font-weight: 500;
        font-size: 21px;
        line-height: 150%;
        text-transform: uppercase;
        margin: auto;
        text-align: center;
        color: var(--secondary-text-color);
    }
}

.cartItem {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 8px 16px;
    column-gap: 16px;

    .icon {
        width: 60px;
        height: 60px;
    }

    .content {  
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: var(--primary-text-color);
        width: calc(100% - 60px - 16px);

        .name {
            width: fit-content;
            font-weight: 700;
        }

        .description {
            word-wrap: break-word;
        }

        .countWrapper {
            display: flex;
            align-items: center;
            width: fit-content;
            
            .price {
                margin: 0 0 0 16px;
            }
        }
    }
}

.products {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 12px 0;

    .product {
        display: flex;
        margin: 0 20px;
        padding: 8px 0 0;

        .image {
            max-width: 260px;
            max-height: 180px;
            margin: 0 12px 0 0;
            border-radius: 8px;
            object-fit: contain;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text {
                width: 100%;
                padding: 0 0 8px;
                font-size: 16px;
                color: var(--secondary-text-color);
            }
        }
    }
}